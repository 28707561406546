import React, { useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import './CounterFinder.css';
import AddStatistics from '../utils/AddStatistics';
import {GET_COUNTERS_URL, ADD_NEW_VALUE_URL} from '../consts.js';

// let GET_COUNTERS_URL = "https://dubkibobri.freemyip.com/get-counters"; // process.env.REACT_APP_CF_GET_COUNTERS;
// let ADD_NEW_VALUE_URL = "https://dubkibobri.freemyip.com/add-new-value"; //process.env.REACT_APP_CF_ADD_NEW_VALUE;
// if (DevMode) {
//     GET_COUNTERS_URL = "https://nich20001.fvds.ru/get-counters"; // process.env.REACT_APP_CF_GET_COUNTERS;
//     ADD_NEW_VALUE_URL = "https://nich20001.fvds.ru/add-new-value"; //process.env.REACT_APP_CF_ADD_NEW_VALUE;
// }

function CounterFinder({ onClose, userID, regionID }) {
    const [accountNumber, setAccountNumber] = useState('');
    const [counters, setCounters] = useState([]);
    const [error, setError] = useState('');
    const [newCounterValues, setNewCounterValues] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // Функция для изменения новых значений счетчиков
    const handleNewCounterValueChange = useCallback((counterId, value) => {
        setNewCounterValues(prevValues => ({...prevValues, [counterId]: value}));
    }, []);

    // Функция для запроса информации о счетчиках
    const findCounters = useCallback(async () => {
        if (!accountNumber.trim()) {
            setError('Введите номер лицевого счета');
            return;
        }
        setIsLoading(true);
        const requestBody = JSON.stringify({ ls: accountNumber });

        try {
            const response = await axios.post(GET_COUNTERS_URL, requestBody, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.data && Array.isArray(response.data.data)) {
                setCounters(response.data.data);
                setError('');
                // AddStatistics(userID, regionID, 9);
            } else {
                throw new Error("Ответ сервера не содержит данных счетчиков");
            }
        } catch (err) {
            setError('Ошибка при получении данных счетчиков: ' + err.message);
        } finally {
            setIsLoading(false);
        }
    }, [accountNumber]);

    // Функция для отправки новых показаний счетчиков
    const submitNewCounterValue = useCallback(async () => {
        const filteredReadings = counters.map(counter => ({
            NomerTarifa: counter.NomerTarifa,
            newValue: newCounterValues[`${counter.RowID}-${counter.NomerTarifa}`]
        })).filter(reading => reading.newValue);

        if (filteredReadings.length !== counters.length) {
            alert("Введите показания для всех тарифов счетчика.");
            return;
        }

        const requestData = {
            CheckAvg: false,
            ls: accountNumber,
            NomerUslugiForBilling: counters[0].NomerUslugi,
            Source: 1,
            RowID: counters[0].RowID,
            pokazanie: filteredReadings
        };

        try {
            const response = await axios.post(ADD_NEW_VALUE_URL, requestData, {
                headers: {
                    "php-auth-user": "TG_API", // process.env.REACT_APP_API_USER,
                    "php-auth-pw": "yhHVHgFuDQkf2MrTlksw7lfK8fckGdI", // process.env.REACT_APP_API_PW,
                    "Content-Type": "application/json"
                }
            });

            if (response.status === 200 && response.data.result) {
                AddStatistics(userID, regionID, 10);
                alert("Все показания успешно переданы");
            } else {
                throw new Error(response.data.error || "Неизвестная ошибка"); // Используем 'error' ключ из ответа бэкенда
            }
        } catch (err) {
            if (err.response) {
                // Если ошибка пришла с сервера и содержит тело в формате JSON
                const errorData = err.response.data;
                const errorText = errorData.errorText || "Неизвестная ошибка";
                alert(`Ошибка при передаче показаний: ${errorText}`);
            } else {
                // Если нет ответа от сервера или другая сетевая ошибка
                alert('Произошла ошибка при передаче показаний: ' + err.message);
            }
        }

    }, [accountNumber, counters, newCounterValues]);

    return (
        <div className="counter-finder-wrapper">
            <div className="counter-finder-card">
                <h3 className="counter-finder-section-header-top">Передать показания</h3>
                <div>
                    <input
                        type="text"
                        id="accountNumber"
                        value={accountNumber}
                        onChange={e => setAccountNumber(e.target.value)}
                        placeholder="Введите номер лицевого счета"
                        className="counter-finder-form-input"
                    />
                </div>

                {isLoading && <p>Загрузка...</p>}

                {/* Отображение информации о счетчиках, если они есть */}
                {counters.length > 0 && (
                    <div>
                        <h4 className="counter-finder-section-header-bot">Информация о счетчиках:</h4>
                        {counters.map((counter, index) => (
                            <div key={index} className="counter-finder-info">
                                <p style={{fontWeight: 'bold'}}>Номер счетчика: {counter.ZavodNomer}</p>
                                <p>Дата последних показаний: {counter.DatePok}</p>
                                <p>Тариф: {counter.NazvanieTarifa}</p>
                                <p>Предыдущие показания: {counter.PredPok}</p>
                                <input
                                    type="text"
                                    placeholder="Введите новые показания"
                                    onChange={(e) => handleNewCounterValueChange(`${counter.RowID}-${counter.NomerTarifa}`, e.target.value)}
                                    className="counter-finder-meter-input"
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Отображение кнопок действий в зависимости от состояния */}
            <div className="counter-finder-form-actions">
                <button className="counter-finder-button" onClick={onClose}>Назад</button>
                {counters.length === 0 ? (
                    <button className="counter-finder-button" onClick={findCounters}>Найти</button>
                ) : (
                    <button className="counter-finder-button"
                            onClick={() => submitNewCounterValue()}>Передать</button>
                )}
            </div>

            {/* Отображение ошибок, если они есть */}
            {error && <p className="counter-finder-error-message">{error}</p>}

            {/*<br/>*/}
            {/*<span>{userID}</span>*/}
        </div>
    );
}

export default CounterFinder;
