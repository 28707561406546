import axios from 'axios';
import {STATISTICS_URL} from '../consts.js';

// const STATISTICS_URL = "https://dubkibobri.freemyip.com/add-statistics"; // process.env.REACT_APP_STATISTICS_URL;

const AddStatistics = (userID, regionID, pageID) => {
    axios.get(`${STATISTICS_URL}?user_id=${userID}&region_id=${regionID}&page_id=${pageID}`)
    .then(response => {})
    .catch(err => {});
}

export default AddStatistics;
