const AppVersion = "20241210";
const DevMode = false;
// const DevMode = false;

export { AppVersion };
export { DevMode };

// let GET_COUNTERS_URL = "https://dubkibobri.freemyip.com/get-counters"; // process.env.REACT_APP_CF_GET_COUNTERS;
// let ADD_NEW_VALUE_URL = "https://dubkibobri.freemyip.com/add-new-value"; //process.env.REACT_APP_CF_ADD_NEW_VALUE;
let GET_COUNTERS_URL = "https://dubkibobri.fvds.ru/get-counters"; // process.env.REACT_APP_CF_GET_COUNTERS;
let ADD_NEW_VALUE_URL = "https://dubkibobri.fvds.ru/add-new-value"; //process.env.REACT_APP_CF_ADD_NEW_VALUE;
if (DevMode) {
    GET_COUNTERS_URL = "https://nich20001.fvds.ru/get-counters"; // process.env.REACT_APP_CF_GET_COUNTERS;
    ADD_NEW_VALUE_URL = "https://nich20001.fvds.ru/add-new-value"; //process.env.REACT_APP_CF_ADD_NEW_VALUE;
}

// let REGIONS_URL = "https://dubkibobri.freemyip.com/regions"; // process.env.REACT_APP_REGIONS_URL;
// let REGION_LINKS_URL = "https://dubkibobri.freemyip.com/region-links"; // process.env.REACT_APP_REGION_LINKS_URL;
// let FAQS_URL = "https://dubkibobri.freemyip.com/faqs"; // process.env.REACT_APP_FAQS_URL;
let REGIONS_URL = "https://dubkibobri.fvds.ru/regions"; // process.env.REACT_APP_REGIONS_URL;
let REGION_LINKS_URL = "https://dubkibobri.fvds.ru/region-links"; // process.env.REACT_APP_REGION_LINKS_URL;
let FAQS_URL = "https://dubkibobri.fvds.ru/faqs"; // process.env.REACT_APP_FAQS_URL;
if (DevMode) {
    REGIONS_URL = "https://nich20001.fvds.ru/regions"; // process.env.REACT_APP_REGIONS_URL;
    REGION_LINKS_URL = "https://nich20001.fvds.ru/region-links"; // process.env.REACT_APP_REGION_LINKS_URL;
    FAQS_URL = "https://nich20001.fvds.ru/faqs"; // process.env.REACT_APP_FAQS_URL;
}

// const STATISTICS_URL = "https://dubkibobri.freemyip.com/add-statistics"; // process.env.REACT_APP_STATISTICS_URL;
const STATISTICS_URL = "https://dubkibobri.fvds.ru/add-statistics";

export { GET_COUNTERS_URL };
export { ADD_NEW_VALUE_URL };
export { REGIONS_URL };
export { REGION_LINKS_URL };
export { FAQS_URL };
export { STATISTICS_URL };
